import _get from 'lodash/get';
import { connect, ConnectedProps } from 'react-redux';
import { useRouter } from 'next/router';
import Auth, { AuthStateInterface } from 'fired-up-core/src/stores/auth';
import ClipLoader from 'react-spinners/ClipLoader';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import { colors as variableColors } from '../../styles/variables';
import MetaProvider from '../../components/MetaProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    backgroundColor: variableColors['color-gray-background'],
    height: '100vh',
    textAlign: 'center',
  },
  signinBox: {
    marginTop: '50%',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: variableColors.matchingBlue,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34.3671px',
    lineHeight: '42px',
  },
  titleHighlightContainer: {
    marginLeft: theme.spacing(1),
  },
  titleHighlight: {
    padding: '3px 8px',
    color: '#fff',
    backgroundColor: '#1D68D9',
    borderRadius: '5px',
    fontSize: '26px',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(8),
    height: '100%',
  },
  loginButtonContainer: {
    '& img:last-of-type': {
      display: 'none',
    },
    '&:focus': {
      '& img:first-of-type': {
        display: 'none',
      },
      '& img:last-of-type': {
        display: 'inline-block',
      },
    },
  },
  loginButton: {
    marginTop: theme.spacing(1),
    width: '220px',
  },
  loginHelp: {
    position: 'absolute',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
    bottom: theme.spacing(4),
    left: 0,
    fontSize: 10,
    color: '#444',
    '& a': {
      color: '#444',
    },
  },
  errorText: {
    marginTop: theme.spacing(2),
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '90%',
    fontSize: '12px',
  },
  privacy: {
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    maxWidth: '90%',
  },
}));

type PropsFromRedux = ConnectedProps<typeof connector>;
type LoginPageProps = PropsFromRedux & {
  auth: AuthStateInterface;
};

function LoginPage(props: LoginPageProps) {
  const classes = useStyles({});
  const router = useRouter();
  const context = _get(router, 'query.context');
  const [loginErrorEmail, setLoginErrorEmail] = useState(false);
  const disabledError = props.auth.error === 'ACCOUNT_DISABLED';

  // get login status
  useEffect(() => {
    props.fetchStatus();
  }, []);

  // If logged in...
  useEffect(() => {
    if (props.auth.isLoggedIn) {
      if (props.auth.role === 'super-admin') {
        const redirectUrl =
          context === 'extension' ? '/embed/matching' : '/welcome';
        window.location.href = redirectUrl;
        router.push(redirectUrl, undefined, { shallow: true });
      } else {
        setLoginErrorEmail(props.auth.user.email);
        props.handleLogout();
      }
    }
  }, [props.auth.isLoggedIn, props.auth.role]);

  function handleLogin(e) {
    e.preventDefault();
    const showPrompt = !!loginErrorEmail;
    props.handleLogin(showPrompt);
    setLoginErrorEmail(false);
  }

  function renderError() {
    if (disabledError) {

      return (
        <Typography color="error" className={classes.errorText}>
          Thank you for being a Fired Up user! Your account is no longer active.
          Follow{' '}
          <a target="_blank" href="https://intercom.help/fired-up/en/articles/4603014-uninstalling-the-fired-up-chrome-extension">
            these instructions
          </a>{' '}
          to remove Fired Up from your Chrome extensions.
        </Typography>
      );
    } else if (loginErrorEmail) {

      return (
        <Typography color="error" className={classes.errorText}>
          It looks like that email ({loginErrorEmail}) is not authorized.
          <br />
          Try using your organization-specific email to login.
          <br />
          If you have any further troubles, contact us at{' '}
          <a href="mailto:support@patronage.org">support@firedup360.com</a>.
        </Typography>
      );
    } else {

      return null;
    }
  }

  function renderLoginStatus() {
    if (!props.auth.hasCheckedAuth) {
      return <ClipLoader />;
    }

    return (
      <div className={classes.loginContainer}>
        <div className={classes.signinBox}>
          <div className={classes.title}>
            Fired Up
            <div className={classes.titleHighlightContainer}>
              <span className={classes.titleHighlight}>360</span>
            </div>
          </div>
          {!disabledError && (
            <div>
              <a
                href="#login"
                onClick={handleLogin}
                role="button"
                className={classes.loginButtonContainer}
              >
                <img
                  className={classes.loginButton}
                  src="/static/img/btn_google_signin_light_normal_web@2x.png"
                />
                <img
                  className={classes.loginButton}
                  src="/static/img/btn_google_signin_light_pressed_web@2x.png"
                />
              </a>
            </div>
          )}
          {renderError()}
        </div>
        <div className={classes.loginHelp}>
          <div className={classes.privacy}>
            This extension collects personal identifying information about you,
            personal identifying information about others that is available to
            you on social media services, and information about others’
            interactions with social media content.
            <br />
            By using this app you agree to our{' '}
            <a target="_blank" href="https://firedup360.com/privacy">
              Privacy Policy
            </a>
            .
          </div>
          &copy; 2020 Patronage
          <br />
          Need help? <a href="mailto:support@patronage.org">Contact us</a>
        </div>
      </div>
    );
  }

  return (
    <>
      <CssBaseline />
      <MetaProvider
        meta={{
          noIndex: true,
          title: 'Login',
        }}
      />
      <Container maxWidth="xs" className={classes.container}>
        {renderLoginStatus()}
      </Container>
    </>
  );
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  fetchStatus: () => dispatch(Auth.status()),
  handleLogin: (showPrompt = false) => dispatch(Auth.login(showPrompt)),
  handleLogout: () => dispatch(Auth.logout()),
});

const connector = connect(mapState, mapDispatch);

export default connector(LoginPage);
